<template>
    <b-overlay :show="isLoading" rounded="sm">
        <!--<b-row class="pr-1 pl-1">
            <b-col md="12" class="mb-1 text-right">
                <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        @click=""
                >
                    <feather-icon
                            icon="CheckSquareIcon"
                            class="mr-50"
                    />
                    Cursos por Defecto
                </b-button>
                <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        @click="checkAllColumns"
                        class="ml-1"
                >
                    <feather-icon
                            icon="CheckSquareIcon"
                            class="mr-50"
                    />
                    Todas los Cursos
                </b-button>
            </b-col>
            <b-col md="6">
                <h6 class="text-primary">Cursos Seleccionados</h6>
                <draggable
                        :list="coursesSelected"
                        tag="ul"
                        group="people"
                        class="list-group list-group-flush cursor-move"
                        @change="cambio"
                >
                    <b-list-group-item
                            v-for="listItem in coursesSelected"
                            :key="listItem.id"
                            tag="li"
                    >
                        <div class="d-flex">
                            <div class="ml-25">
                                <b-card-text class="mb-0 font-weight-bold">
                                    {{ listItem.name }}
                                </b-card-text>
                            </div>
                        </div>
                    </b-list-group-item>
                </draggable>
            </b-col>
            <b-col md="6">
                <h6 class="text-primary">Cursos No Seleccionados</h6>

                <draggable
                        :list="coursesNotSelected"
                        tag="ul"
                        group="people"
                        class="list-group list-group-flush cursor-move"
                        @change="cambio"
                >
                    <b-list-group-item
                            v-for="listItem in coursesNotSelected"
                            :key="listItem.id"
                            tag="li"
                    >
                        <div class="d-flex">
                            <div class="ml-25">
                                <b-card-text class="mb-0 font-weight-bold">
                                    {{ listItem.name }}
                                </b-card-text>
                            </div>
                        </div>
                    </b-list-group-item>
                </draggable>
            </b-col>
        </b-row>-->
        <div v-if="isTrainingSeleted()==true" class="card">
            <div class="card-body">
                <b-form-group
                        id="input-group-description"
                        label-for="input-2"
                >
                    <v-data-table
                            :headers="headers"
                            :items="getCourses"
                            item-key="id"
                            class="elevation-1"
                            locale="es"
                            :hide-default-footer="true"
                            v-model="coursesSelected"

                    >
                        <template v-slot:item.image="{ item }">
                            <div class="">
                                <img
                                        :src="item.image"
                                        class="image-table medium align-self-start"
                                        alt=""
                                />
                            </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="text-center">
                                <a @click="goDetail(item)" v-b-modal.modal-course-details
                                   class="btn btn-icon btn-light btn-sm">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/Design/ZoomMinus.svg"
                                  ></inline-svg>
                                </span>
                                </a>
                            </div>
                        </template>
                        <template slot="no-data">
                            Ningun curso para listar
                        </template
                        >                    <template v-slot:item.image="{ item }">
                        <div class="">
                            <img
                                    :src="item.image"
                                    class="image-table medium align-self-start"
                                    alt=""
                            />
                        </div>
                    </template>
                        <!-- estado seleccion inicio -->
                        <template v-slot:item.courseSelection="{ item }">
                            <b-badge :variant="statusColor(item)" v-if="statusColor(item)=='success'">
                                Curso Seleccionado
                            </b-badge>
                            <b-badge v-else>
                                -
                            </b-badge>
                        </template>
                        <!-- estado seleccion fin -->
                        <template v-slot:item.actions="{ item }">
                            <div class="text-center">
                                <a @click="goDetail(item)" v-b-modal.modal-course-details
                                   class="btn btn-icon btn-light btn-sm">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/Design/ZoomMinus.svg"
                                  ></inline-svg>
                                </span>
                                </a>
                            </div>
                        </template>
                        <template slot="no-data">
                            Ningun curso para listar
                        </template
                        >                    <template v-slot:item.image="{ item }">
                        <div class="">
                            <img
                                    :src="item.image"
                                    class="image-table medium align-self-start"
                                    alt=""
                            />
                        </div>
                    </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="text-center">
                                <a @click="goDetail(item)" v-b-modal.modal-course-details
                                   class="btn btn-icon btn-light btn-sm">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/Design/ZoomMinus.svg"
                                  ></inline-svg>
                                </span>
                                </a>
                            </div>
                        </template>
                        <template slot="no-data">
                            Ningun curso para listar
                        </template
                        >                    <template v-slot:item.image="{ item }">
                        <div class="">
                            <img
                                    :src="item.image"
                                    class="image-table medium align-self-start"
                                    alt=""
                            />
                        </div>
                    </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="text-center">
                                <a @click="goDetail(item)" v-b-modal.modal-course-details
                                   class="btn btn-icon btn-light btn-sm">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/Design/ZoomMinus.svg"
                                  ></inline-svg>
                                </span>
                                </a>
                            </div>
                        </template>
                        <template slot="no-data">
                            Ningun curso para listar
                        </template>
                    </v-data-table>
                </b-form-group>

            </div>
            <div class="card-footer">
                <button v-if="canSetSelection()" class="btn btn-success float-right" @click="sendSeletion">Confirmar</button>
            </div>
        </div>
        <div v-else class="card">
            <div class="card-body">
                <label class="" style="position: center;">Debe seleccionar un training de la lista para ver los
                    cursos.</label>
            </div>
        </div>
        <b-modal id="modal-course-details" title="Datos del curso">
            <div class="row" v-if="coursesSelectedForView">
                <div class="col-md-12">
                    <div class="card-body pb-10 pb-2">
                        <div class="row ml-2 mt-2">
                            <div class="col-md-4">
                                <img :src="coursesSelectedForView.image" class="image-view" alt=""/>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="label-detail">Nombre</div>
                                        <div>{{ coursesSelectedForView.name }}</div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="label-detail">Descripción</div>
                                        <div>{{ coursesSelectedForView.description }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ml-2" v-if="coursesSelectedForView">
              <div class="col-md-12">
                <div class="label-detail">Reseña</div>
                <!--<div class="html-container">-->
                <div v-html="coursesSelectedForView.review"></div>
                <!--</div>-->
              </div>
            </div>
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="primary" @click="cancel()">
                    Cerrar
                </b-button>
            </template>
        </b-modal>
    </b-overlay>
</template>

<script>
    import {SEND_CONFIRMATION_COURSES} from "@/store/modules/orders.module";
    import draggable from "vuedraggable";

    export default {
        name: "TrainingsCourse",
        data() {
            return {
                courseList: [],
                coursesNotSelected: [],
                coursesSelected: [],
                OrdenSelected: [],
                coursesSelectedForView: null,
                isLoading: false,
                isLoadingTable: false,
                options: {},
                currentPage: 1,
                itemsPerPage: 10,
                headers: [
                    {text: "Imagen", value: "image", align: "center", sortable: false, width: 100},
                    {text: "Estado de seleccion", value: "courseSelection", align: "center", sortable: false, width: 100},
                    {text: "Nombre", value: "name", align: "center", sortable: false},
                    {text: "Descripción", value: "description", align: "center", sortable: false},
                    {text: "Acciones", value: "actions", align: "center", sortable: false},
                ],
                dtSearch: ""
            };
        },
        components: {draggable},
        mounted() {
            this.loadCourseList()
        },
        methods: {
            loadCourseList() {
                this.isLoading = true
                setTimeout(() => {
                    this.courseList = this.$store.state.orders.coursesList
                    this.coursesNotSelected = this.$store.state.orders.coursesList
                    this.OrdenSelected = this.$store.state.orders.orderSelected
                    this.isLoading = false
                }, 500)
            },
            statusColor(item) {
                if(this.OrdenSelected.coursesSelection.length >0){
                let order = this.OrdenSelected.coursesSelection.filter(p=> p.id == item.id);
                    if(order.length > 0 ){
                        return 'success'
                    }
                }
            },
            sendSeletion() {
                let scope = this
                this.isLoading = true
                this.$store
                    .dispatch(SEND_CONFIRMATION_COURSES, {
                        "orderId": this.$store.state.orders.orderSelected.id,
                        "courseList": this.coursesSelected.map(function (course) {
                            return course.id
                        }),
                    })
                    .then((response) => {
                        if(response.data.status == true) {
                            scope.$bvToast.toast(
                                response.data.message,
                                {
                                    title: "Seleccion de cursos",
                                    variant: "success",
                                    solid: true,
                                }
                            )
                            this.$store.state.orders
                                .ordersList
                                .find(order =>
                                    order.id === this.$store.state.orders.orderSelected.id).course_selection = this.coursesSelected
                        }else{
                            scope.$bvToast.toast(
                                response.data.message,
                                {
                                    title: "Seleccion de cursos",
                                    variant: "danger",
                                    solid: true,
                                }
                            );
                        }
                        scope.isLoading = false
                    }).catch(() => {
                    scope.$bvToast.toast(
                        "Se ha generado un error",
                        {
                            title: "Seleccion de cursos",
                            variant: "danger",
                            solid: true,
                        }
                    );
                    scope.isLoading = false
                });
            },
            isTrainingSeleted() {
                return this.$store.state.orders.orderSelected != null
            },
            canSetSelection() {
                return this.$store.state.orders.orderSelected.canStudentSelect
            },
            goDetail(item) {
                this.coursesSelectedForView = item
            },

        },
      computed:{
          getCourses(){
            return this.courseList.map(item => ({
              id: item.id,
              image: item.image,
              name: item.name,
              description: item.description,
              review: item.review,
            }))
          }
      }
    };
</script>

<style scoped></style>
