<template>
    <b-overlay :show="isLoading" rounded="sm">
    <div>
        <div class="row">
            <div class="container-fluid text-center"><h4 class="text">A continuación seleccione el o los training que considere pertinentes para mejorar su desempeño laboral.</h4></div>

            <div class="container-fluid">
                <v-data-table
                        :headers="headers"
                        :items="getTraining"
                        item-key="id"
                        class="elevation-1"
                        locale="es"
                        :hide-default-footer="true"

                >
                    <template v-slot:item.image="{ item }">
                        <div class="">
                            <img
                                    :src="item.image"
                                    class="image-table medium align-self-start"
                                    alt=""
                            />
                        </div>
                    </template>
                    <!-- estado inicio -->
                    <template v-slot:item.name_for_student="{ item }">
                        <div class="text-center">
                            <b-badge class="center" :variant="statusStudent(item.name_for_student)">{{item.name_for_student}}</b-badge>
                        </div>

                    </template>
                    <!-- estado fin -->
                    <template v-slot:item.actions="{ item }">
                        <div class="text-center">
                            <!--<v-icon
                                   small
                                   class="mr-2"
                                   v-b-modal="'cursos-modal'"
                                   @click="selectCourseList(item)"
                           >
                               mdi-loupe
                           </v-icon>-->

                           <v-icon
                                   small
                                   class="mr-2"
                                   v-b-modal="'gestion-cursos-modal'"
                                   @click="selectCourses(item)"
                           >
                               mdi-loupe
                           </v-icon>
               </div>
           </template>
           <template slot="no-data">
               Ningun curso para listar
           </template>
       </v-data-table>
       <b-modal id="cursos-modal" :title="nombreTraining" size="xl" centered>
           <div class="fluid">
               <TrainingsCourse ref="trainingCourseListComponent"></TrainingsCourse>
           </div>
           <!--<div class="container-fluid">
             <TrainingList @reloadCourseList="reloadCourseList"></TrainingList>
           </div>
           <div class="col-xxl-8">
             <TrainingsCourse ref="trainingCourseListComponent"></TrainingsCourse>
           </div>-->
                    <template #modal-footer="{ ok, cancel, hide }">
                        <b-button size="sm" variant="primary" @click="cancel()">
                            Cerrar
                        </b-button>
                    </template>
                </b-modal>
                <b-modal
                        @ok="handleOk"
                        @cancel="cancel"
                        id="gestion-cursos-modal"
                        :title="nombreTraining"
                        size="xl"
                        centered
                        v-if="isVisible"
                        @hidden="resetModal"
                >
                    <form ref="form" @submit.stop.prevent="sendSeletion">
                    <b-row class="pr-1 pl-1">
                        <b-col md="12">
                            <div>A continuación se presentan una serie de cursos, seleccione aquel o aquellos cursos que considere necesarios para mejorar su desempeño actual. Priorice sus opciones según su necesidad.
                            </div>
                            <div>Para conocer más sobre algún curso seleccione el <v-icon color="grey lighten-1">mdi-information</v-icon></div>
                            <div v-if="!selectionStateFinalized && !alerta" class="text-danger">* Debe seleccionar como mínimo {{orden.minimal_choice}} cursos</div>

                        </b-col>

                        <b-col md="1"></b-col>
                        <b-col md="5">
                            <template>
                                <v-card
                                        class="mx-auto"
                                        tile
                                >
                                    <v-list :nav="false" >
                                        <v-subheader>TODOS LOS CURSOS</v-subheader>
                                        <v-list-item-group color="primary" multiple>
                                            <v-list-item
                                                    v-for="(item, i) in coursesList"
                                                    :key="i"
                                            >
                                                <v-list-item-action>
                                                    <v-checkbox
                                                            v-model="item.select"
                                                            color="primary"
                                                            @click="selectCourse(item)"
                                                            :disabled="selectionStateFinalized"
                                                    ></v-checkbox>
                                                </v-list-item-action>
                                                <v-list-item-icon>
                                                    <img
                                                            :src="item.image"
                                                            class="image-table medium align-self-start"
                                                            alt=""
                                                    />
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn icon  v-b-modal.modal-course-details @click="goDetail(item)">
                                                        <v-icon color="grey lighten-1">mdi-information</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </template>
                        </b-col>
                        <b-col md="1"></b-col>
                        <b-col md="4"><template>
                            <v-card
                                    class="mx-auto"
                                    min-height="100%"
                                    tile
                            >
                                <v-list disabled>
                                    <v-subheader>CURSOS SELECCIONADOS</v-subheader>
                                    <v-list-item-group v-model="coursesSelected" color="primary">
                                        <v-list-item
                                                v-for="(item, i) in coursesSelected"
                                                :key="i"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>
                        </template>

                        </b-col>
                        <b-col md="1"></b-col>
                        <b-col md="12" class="nota">
                            <div>* Para confirmar su selección presione el botón “Finalizar”.
                                <br>
                                * Para guardar la selección y poder realizar cambios más tarde presione el botón “Continuar más tarde”.
                                <br>
                                * Para volver a visualizar otros trainings presione el botón “Atrás”.
                            </div>
                        </b-col>
                    </b-row>
                    </form>
                    <template #modal-footer>
                        <b-button v-b-modal.gestion-cursos-modal @click="cancel">Atrás</b-button>
                        <b-button v-if="!selectionStateFinalized" v-b-modal.gestion-cursos-modal variant="primary "@click="handleOk($event,0)">Continuar más tarde</b-button>
                        <b-button v-if="!selectionStateFinalized" v-b-modal.gestion-cursos-modal variant="primary" @click="handleOk($event,1)">Finalizar</b-button>
                    </template>
                </b-modal>
                <b-modal id="modal-course-details" title="Datos del curso">
                    <v-card
                            class="mx-auto"
                    >
                        <v-img
                                :src="coursesSelectedForView.image"
                                height="200px"
                                cover
                        ></v-img>
                        <v-card-title>
                          {{coursesSelectedForView.name}}
                        </v-card-title>
                        <v-card-subtitle>
                            {{coursesSelectedForView.description}}
                        </v-card-subtitle>
                        <v-divider></v-divider>
                                <v-card-text>
                                    {{coursesSelectedForView.review}}
                                </v-card-text>
                    </v-card>
                    <!--<div class="row" v-if="coursesSelectedForView">
                        <div class="col-md-12">
                            <div class="card-body pb-10 pb-2">
                                <div class="row ml-2 mt-2">
                                    <div class="col-md-4">
                                        <img :src="coursesSelectedForView.image" class="image-view" alt=""/>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="label-detail">Nombre</div>
                                                <div>{{ coursesSelectedForView.name }}</div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="label-detail">Descripción</div>
                                                <div>{{ coursesSelectedForView.description }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ml-2" v-if="coursesSelectedForView">
                        <div class="col-md-12">
                            <div class="label-detail">Reseña</div>

                            <div v-html="coursesSelectedForView.review"></div>

                        </div>
                    </div>
                    -->
                    <template #modal-footer="{ ok, cancel, hide }">
                        <b-button size="sm" variant="primary" @click="cancel()">
                            Cerrar
                        </b-button>
                    </template>
                </b-modal>
            </div>
        </div>
    </div>
    </b-overlay>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import TrainingList from "./TrainingList";
    import TrainingsCourse from "./TrainingCourses";
    import {
        GET_ORDERS_LIST,
        ORDER_SET_COURSES_LIST,
        SET_ORDER_SELECTED,
      SEND_CONFIRMATION_COURSES
    } from "@/store/modules/orders.module";
    import draggable from "vuedraggable";
    import selects from "@/view/pages/vuetify/forms/Selects.vue";

    export default {
        name: "TrainingsIndex",
        data() {
            return {
                filtersData: {
                    enterprise_id: "",
                    training_id: "0",
                    start_date: "",
                    end_date: "",
                    itemsPerPage: 50,
                    page: 1,
                },
                active: false,
                isLoading: false,
                isActive: false,
                srcTrainingImage: '',
                nombreTraining: '',
                typesubmit: false,
                selectionStateFinalized: false,
                srcTrainingDescription: '',
                orderList: [],
                coursesList: [],
                coursesNotSelected: [],
                coursesSelected: [],
                coursesSelectedForView: [],
                orden: [],
                idOrden: null,
                isVisible:false,
                stateTraining:0,
                headers: [
                    {text: "Imagen", value: "image", align: "center", sortable: false},
                    {text: "Nombre", value: "name", align: "center", sortable: false},
                    {text: "Estado", value: "name_for_student", align: "center", sortable: false},
                    {text: "Fecha Inicio", value: "start_date", align: "center", sortable: false},
                    {text: "Fecha Fin", value: "end_date", align: "center", sortable: false},
                    {text: "Acciones", value: "actions", align: "center", sortable: false},

                ],
                headersCourse: [
                    {text: "Imagen", value: "image", align: "left", sortable: false, width: 100},
                    {text: "Nombre", value: "name", sortable: false},
                    {text: "Acciones", value: "actions", align: "center", sortable: false},
                ],
            };
        },
        components: {TrainingsCourse, TrainingList, draggable},
        mounted() {
            this.getTrainingList();
            this.$store.dispatch(SET_BREADCRUMB, [{title: "Listado de Training"}]);
        },
        methods: {
            statusStudent(value) {
                if (value == 'Finalizada') {
                    return 'danger'
                } else if (value == 'No iniciado') {
                    return 'warning'
                } else if (value == 'Iniciada') {
                    return 'success'
                }
            },
            getTrainingList() {
                let scope = this
                scope.isLoading = true
                this.$store.dispatch(GET_ORDERS_LIST, {
                    page: 1,
                    itemsPerPage: 50,
                    search: ''
                }).then(() => {
                    scope.isLoading = false
                    this.orderList = this.$store.state.orders.ordersList
                })
            },
            reloadCourseList() {
                this.$refs.trainingCourseListComponent.loadCourseList()
            },
            selectCourseList(item) {
                this.$store.commit(SET_ORDER_SELECTED, item)
                this.$store.commit(ORDER_SET_COURSES_LIST, item.courses)
                this.nombreTraining =  item.name
            },
          selectCourses(item) {
              //bookmarks.value.findIndex(i => i.route === item.route)
            //this.coursesNotSelected = []
            this.coursesSelected = []
            this.coursesList = []
              //console.log('courses',  item)
            let selCourse = [];
            let course = [];
            //sel = item.courses;
            //this.coursesList = item.courses;
            /*item.coursesSelection.forEach(function(i){
              sel = sel.filter(p=> p.id !== i.id)
            });*/
              let select = false
            item.courses.forEach(function(i){
                let result=item.coursesSelection.filter(x=>x.id==i.id);
                course.push({ id: i.id, name: i.name, description:i.description, image:i.image, select:result.length == 0 ? false : true, review:i.review});
            });
            this.coursesList = course
            //this.coursesNotSelected = sel
            this.coursesSelected = item.coursesSelection
            this.idOrden = item.id
            this.orden = item
            this.selectionStateFinalized = item.selection_state_finalized
            this.nombreTraining = item.name
              this.isVisible = true
          },
            selectAll(){
                for(let i=0; i< this.coursesNotSelected.length; i++){
                    this.coursesSelected.push(this.coursesNotSelected[i]);
                }
                this.coursesNotSelected = []
            },
            checkFormValidity() {
                const valid = this.orden.minimal_choice <= this.coursesSelected.length ? true : false
                //this.nameState = valid
                return valid
            },
            resetModal() {
              this.getTrainingList();
            },
            cancel(){
                this.getTrainingList();
                this.isVisible = false
            },
            selectCourse(item){
                if(item.select){
                    this.coursesSelected.push(item);
                }else {
                    this.coursesSelected = this.coursesSelected.filter(curso => curso.id != item.id);
                }

            },

            finalizar(){
                this.stateTraining = 1
            },
            goDetail(item) {
                this.coursesSelectedForView = item
            },
            handleOk(bvModalEvent, state) {
                this.stateTraining = state
                console.log('state',state)
                // Prevent modal from closing
                bvModalEvent.preventDefault()
                // Trigger submit handler
                this.sendSeletion()
            },
          sendSeletion() {
              // Exit when the form isn't valid
              if (!this.checkFormValidity()) {
                  return
              }
              let scope = this
              this.isLoading = true
              this.isVisible = false
              //this.$root.$emit('hide::modal', 'gestion-cursos-modal')
              //this.$bvModal.hide('gestion-cursos-modal')

              this.$store
                  .dispatch(SEND_CONFIRMATION_COURSES, {
                      "orderId": this.idOrden,
                      "finalized":this.stateTraining,
                      "courseList": this.coursesSelected.map(function (course) {
                          return course.id
                      }),
                  })
                  .then((response) => {
                      if (response.data.status == true) {
                          scope.$bvToast.toast(
                              response.data.message,
                              {
                                  title: "Selección de cursos",
                                  variant: "success",
                                  solid: true,
                              }
                          )
                          /*this.$store.state.orders
                                .ordersList
                                .find(order =>
                                        order.id === this.$store.state.orders.orderSelected.id).course_selection = this.coursesSelected*/
                          this.getTrainingList();
                      } else {
                          scope.$bvToast.toast(
                              response.data.message,
                              {
                                  title: "Selección de cursos",
                                  variant: "danger",
                                  solid: true,
                              }
                          );
                      }
                      scope.isLoading = false
                  }).catch(() => {
                  scope.$bvToast.toast(
                      "Se ha generado un error",
                      {
                          title: "Selección de cursos",
                          variant: "danger",
                          solid: true,
                      }
                  );
                  scope.isLoading = false
              });
              // Hide the modal manually
              //this.$nextTick(() => {

              //})
          }

        },
        computed: {
            getTraining() {
                return this.orderList.map(item => ({
                    id: item.order.id,
                    image: item.order.training.image,
                    statusSecondName: item.order.statusSecondName,
                    name: item.order.training.name,
                    description: item.order.training.description,
                    start_date: item.order.start_date,
                    end_date: item.order.end_date,
                    select_state: item.order.canStudentSelect,
                    minimal_choice: item.order.minimal_choice,
                    training: item.order.training,
                    courses: item.order.training.courses,
                    coursesSelection: item.order.course_selection,
                    selection_state_finalized: item.selection_state_finalized,
                    name_for_student: item.status.name_for_student,
                    actions: '',
                }))
            },
            alerta(){
               return  this.orden.minimal_choice <= this.coursesSelected.length ? true : false
            }
        },
    };
</script>

<style scoped>
    .v-input--selection-controls__ripple.green--text {
        color: blue !important;
    }
    .v-list-item__icon {
         margin:0!important;
    }
    .v-list-item__icon {
         margin:0!important;
    }
    h4.text{
        color: #ffffff;
        margin-left: 3rem;
    }
    .nota{
        margin-left: 3rem;
    }
    .text-danger{
        font-size: 13px;
        margin-left: 3rem;
    }
</style>
