<template>
  <div>
    <!--<i v-if="getOrder.canStudentSelect" class="fa fa-check-circle mr-2" aria-hidden="true" style="color: green"></i>
    <i v-else class="fa fa-check-circle mr-2" aria-hidden="true" style="color: gray"></i>-->
    <!--<span style="cursor:pointer; margin: 0px 0px 0px 10px; color: #000000;font-size: large;"
          :class="{ trainingActiveItem: isActive}" class="trainingItem"
          @click="selectCourseList()">{{ getTraining.training.name }}</span>-->

    <v-data-table
            :headers="headers"
            :items="getTraining"
            item-key="id"
            class="elevation-1"
            locale="es"
            :hide-default-footer="true"

    >
      <template v-slot:item.image="{ item }">
        <div class="">
          <img
                  :src="item.image"
                  class="image-table medium align-self-start"
                  alt=""
          />
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-center">
          <a v-b-modal="'my-modal'" @click=""


             class="btn btn-icon btn-light btn-sm">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/Design/ZoomMinus.svg"
                                  ></inline-svg>
                                </span>
          </a>
        </div>
      </template>
      <template slot="no-data">
        Ningun curso para listar
      </template
      >
    </v-data-table>
    <b-modal id="my-modal" title="BootstrapVue" size="xl" centered>
      <div class="col-xxl-8">
        <TrainingsCourse ref="trainingCourseListComponent"></TrainingsCourse>
      </div>
      <training-course/>


    </b-modal>
  </div>
</template>

<script>
    import {
        ORDER_SET_COURSES_LIST,
        SET_ORDER_SELECTED
    } from "@/store/modules/orders.module";

    import TrainingCourse from "./TrainingCourses.vue";

    export default {
        name: "TrainingItemList",
        emits: ['clickOnTrainingItem'],
        props: ['order'],
        data() {
            return {
                isActive: false,
                srcTrainingImage: '',
                srcTrainingDescription: '',
              headers: [
                {text: "Imagen", value: "image", align: "start", sortable: false},
                {text: "Nombre", value: "name", align: "start", sortable: false},
                {text: "Estado", value: "statusSecondName", align: "start", sortable: false},
                {text: "Fecha Inicio", value: "start_date", align: "center", sortable: false},
                {text: "Fecha Fin", value: "end_date", align: "center", sortable: false},
                {text: "test", value: "select_state", align: "center", sortable: false},
                {text: "Acciones", value: "actions", align: "center", sortable: false},

              ],
            };
        },
        components: {
          'training-course':TrainingCourse,
        },
        mounted() {

        },
        methods: {
            selectCourseList() {
                this.$store.commit(SET_ORDER_SELECTED, this.getTraining)
                this.$store.commit(ORDER_SET_COURSES_LIST, this.getTraining.training.courses)
                this.$emit('clickOnTrainingItem')
            },
            checkIsActive() {
                this.isActive = (this.$store.state.orders.orderSelected != null && (this.getTraining.id == this.$store.state.orders.orderSelected.id))
            },
        },
        created: function(){
            this.$parent.$on("activeTrainingItemSelect", this.checkIsActive)
        },

        computed:{
            getTraining(){
              return this.order.map(item => ({
                id: item.id,
                image: item.training.image,
                statusSecondName: item.statusSecondName,
                name: item.training.name,
                description: item.training.description,
                start_date: item.start_date,
                end_date: item.end_date,
                select_state : item.canStudentSelect,
                actions: '',
              }))
            }
        }
    };
</script>

<style scoped>
    .trainingActiveItem{
        background-color: #0187b4;
        border-radius: 10px;
        padding: 5px 5px;
    }
    .trainingItem:hover{
        background-color: #25c0b0;
        border-radius: 10px;
        padding: 5px 5px;
    }

    .row:hover{
      background-color:#25c0b0;
      border-radius: 15px;

      ;
    }
</style>
