<template>
    <div class="card">
        <div class="card-body">
            <div class="row mt-2">
                <div class="col-12">
                    <dl>
                        <TrainingItemList :order="orderList" @clickOnTrainingItem="clickOnTrainingItem"></TrainingItemList>
                        <!--<dt v-for="order in orderList" :key="order.id" style="margin: 0px 0px 10px 0px;">
                            <TrainingItemList :order="order" @clickOnTrainingItem="clickOnTrainingItem"></TrainingItemList>
                        </dt>-->
                    </dl>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        GET_ORDERS_LIST,
    } from "@/store/modules/orders.module";
    import TrainingItemList from './TrainingItemList'

    export default {
        name: "TrainingList",
        emits: ['reloadCourseList', 'activeTrainingItemSelect'],
        data() {
            return {
                orderList: []
            };
        },
        components: { TrainingItemList },
        mounted() {
            this.getTrainingList();
        },
        methods: {
            getTrainingList() {
                let scope = this
                scope.isLoading = true
                this.$store.dispatch(GET_ORDERS_LIST, {
                    page: 1,
                    itemsPerPage: 50,
                    search: ''
                }).then(() => {
                    scope.isLoading = false
                    scope.orderList = this.$store.state.orders.ordersList
                })
            },
            clickOnTrainingItem() {
                this.$emit('reloadCourseList')
                this.$emit('activeTrainingItemSelect')
            },
        },
    };
</script>

<style scoped></style>
